import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 10.000000)">
        <path
          d="M29.5227 54V24.9091H39.3523C41.625 24.9091 43.4905 25.2973 44.9489 26.0739C46.4072 26.8409 47.4867 27.8968 48.1875 29.2415C48.8883 30.5862 49.2386 32.1155 49.2386 33.8295C49.2386 35.5436 48.8883 37.0634 48.1875 38.3892C47.4867 39.715 46.4119 40.7566 44.9631 41.5142C43.5142 42.2623 41.6629 42.6364 39.4091 42.6364H31.4545V39.4545H39.2955C40.8485 39.4545 42.0985 39.2273 43.0455 38.7727C44.0019 38.3182 44.6932 37.6742 45.1193 36.8409C45.5549 35.9981 45.7727 34.9943 45.7727 33.8295C45.7727 32.6648 45.5549 31.6468 45.1193 30.7756C44.6837 29.9044 43.9877 29.232 43.0312 28.7585C42.0748 28.2756 40.8106 28.0341 39.2386 28.0341H33.0455V54H29.5227ZM43.2159 40.9318L50.375 54H46.2841L39.2386 40.9318H43.2159Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
